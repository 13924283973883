import './App.css';
import 'rsuite/dist/rsuite.min.css';
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Link } from "react-router-dom";
import Carousel from './components/Carousel.jsx';
import HomePage from './components/HomePage';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Contact from './components/Contact';
import Subscribe from './components/Subscribe';
import Testimonial from './components/Testimonial';
import GoogleAdwords from './components/Services/digitalMarketing/GoogleAdwords';
import Marquee from 'react-fast-marquee';
import Autodialing from './components/Services/autoDialing/Autodialing.jsx';
import Wider from './Wider.jsx';
import Socialmedia from './components/Socialmedia.jsx';
import ProductHome from './components/products/ProductHome.jsx';
import Login from './components/Login.jsx';
import SoftwareDevelopment from './components/Services/softwareDevelopment/softwareDevelopment.jsx';
import WebsiteDevelopment from './components/Services/websiteDevelopment/WebsiteDevelopment.jsx';
import OurPartners from './components/Services/autoDialing/OurPartners.jsx';
import Ourpartnerss from './components/Ourpartnerss.jsx';
import PhotosDevelopment from './components/Services/mediaDevelopment/PhotosDevelopment.jsx';
import VideosDevelopment from './components/Services/mediaDevelopment/VideosDevelopment.jsx';
import Seo from './components/Services/digitalMarketing/Seo.jsx';
import Meta from './components/Services/digitalMarketing/Meta.jsx';
import TermsandCondition from './components/TermsandCondition.jsx';
import PrivacyPolicy from './components/PrivacyPolicy.jsx';
import LegalNotice from './components/LegalNotice.jsx';
import MetaLanding from './components/Services/digitalMarketing/MetaLanding.jsx';
import logo1 from './img/Mlogo1.webp'
import logo2 from './img/Mlogo2.png'
import logo3 from './img/Mlogo3.webp'
import logo4 from './img/Mlogo4.webp'
import logo5 from './img/Mlogo5.jpg'
import logo6 from './img/onlineCake.png'
import logo7 from './img/Mlogo6.jpg'
import logo8 from './img/Mlogo8.jpg'
import logo9 from './img/Mlogo9.jpg'
import Adds from './components/Adds.jsx';

function App() {
  useEffect(()=>{
   console.log( window.location.pathname)
  },[])
  return (
    <Router>
      <Navbar />
      <Carousel />
      <Marquee style={{ marginTop: '5rem', marginBottom: '3rem' }}>
        {/* <Ourpartnerss/> */}
        <div className='col-1' style={{}}><Link to="https://semya.in/"><img src={logo1} className='' style={{ height: '70px', top: '50px' }} alt="" /></Link></div>
        <div className='col-1 mx-4' style={{ marginRight: '8rem' }}><Link to="https://www.queenley.me/"><img src={logo2} className='' style={{ height: '100px', top: '40px' }} alt="" /></Link></div>
        <div className='col-1 mx-4'><Link to="https://theburgercompany.co/"><img src={logo3} className='' style={{ height: '100px', top: '40px' }} alt="" /></Link></div>
        <div className='col-1 mx-1' style={{}}><Link to="https://paanaroma.com/"><img src={logo4} className='' style={{ height: '80px', top: '50px', left: '-39%' }} alt="" /></Link></div>
        <div className='col-1 mx-4'><Link to="https://www.globaldesi.in"><img src={logo5} className='' style={{ height: '120px', top: '30px' }} alt="" /></Link></div>
        <div className='col-1 mx-4'><Link to="https://www.onlinecakencr.com/"><img src={logo6} className='' style={{ height: '70px', top: '60px' }} alt="" /></Link></div>
        <div className='col-1 mx-4' style={{}}><Link to="https://sunasa.in/"><img src={logo7} className='' style={{ width: '180px', height: '140px', top: '-10px' }} alt="" /></Link></div>
        <div className='col-1 mx-4'><Link to="https://vlccpersonalcare.com/"><img src={logo8} className='' style={{ width: '150px', top: '68px' }} alt="" /></Link></div>
        <div className='col-1 mx-4'><Link to="https://www.rangriti.com/"><img src={logo9} className='' style={{ width: '120px', top: '60px' }} alt="" /></Link></div>
        <div className='col-1 mx-4' style={{}}><Link to="https://basicslife.com/"><img src='https://basicslife.com/cdn/shop/files/Untitled-4-01_80x@2x.png?v=1711019726' className='' style={{ height: '100px', top: '40px', left: '15%' }} alt="" /></Link></div>
      </Marquee>
      {/* <div className='text-center'><span className='Our-partners'>Companies We Keep</span></div> */}
      {window.location.pathname !== "/hire" && <div className='Our-partners text-center text-uppercase text-primary'>Trusted by hundreds of Partners</div>
      }
      <Routes>

        <Route path='/' element={<HomePage />}></Route>
        <Route path='/singleProduct' element={<ProductHome />}></Route>
        <Route path='/contact' element={<Contact />}></Route>
        <Route path='/login' element={<Login />}></Route>
        <Route path="/googleAds" element={<GoogleAdwords />}></Route>
        <Route path='/seo' element={<Seo />}></Route>
        <Route path="/metaAds" element={<Meta />}></Route>
        <Route path="/theAutodial" element={<Autodialing />}></Route>
        <Route path='/graphicDesigning' element={<VideosDevelopment />}></Route>
        <Route path='/softwareDevelopment' element={<SoftwareDevelopment />}></Route>
        <Route path='/websiteDevelopment' element={<WebsiteDevelopment />}></Route>
        <Route path='/t&c' element={<TermsandCondition />}></Route>
        <Route path='/privacypolicy' element={<PrivacyPolicy />}></Route>
        <Route path='/SLA' element={<LegalNotice />}></Route>
        <Route path="meta-ads-landingpage" element={<MetaLanding />} />
        <Route path="/hire" element={<Adds />} />
      </Routes>
      <Socialmedia />
      <Testimonial />
      <Subscribe />
      <Footer />
    </Router>
  );
}

export default App;
