import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function SocialMedia() {
  const location = useLocation();

  useEffect(() => {
    let i = 0;
    const colors = ['white', 'green'];

    const interval = setInterval(() => {
      const arrow = document.getElementById('next-video-arrow');
      if (arrow) {
        arrow.style.color = colors[i % colors.length];
        i++;
      }
    }, 100);

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  if (location.pathname === '/login') return null; // Do not render for /login path

  const videos = [
    "https://www.youtube.com/embed/osQo5m7P0Zo?si=ciYjlSj5Y_ktgxQ-",
    "https://www.youtube.com/embed/U3YigUmo-gk?si=l9LTYYQi3vJtSSD3",
    "https://www.youtube.com/embed/thcJcSUZs9I?si=s6yHP_FT7QltRJov",
    "https://www.youtube.com/embed/HcqP5e49qEc",
    "https://www.youtube.com/embed/SgcMglFFeRA",
    "https://www.youtube.com/embed/cX8vI_r5Ekc?s",
    "https://www.youtube.com/embed/kBvSdaxfAas?s",
    "https://www.youtube.com/embed/EOHxyr16IJs",
    "https://www.youtube.com/embed/AnxJlgv0ozk?si",
  ];

  return (
    <div
      id="social-media-container"
      className="social-media container-fluid px-0 d-flex flex-nowrap overflow-auto"
      style={{ backgroundColor: '' }}
    >
      {videos.map((video, index) => (
        <div key={index} className="video-frame mx-0">
          <iframe
            className="rounded shadow"
            width="300"
            src={video}
            title={`Video ${index + 1}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </div>
      ))}
    </div>
  );
}
