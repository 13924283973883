import React from 'react'
import SearchBar from '../../SearchBar';
import Marquee from "react-fast-marquee";
import OurPartners from './OurPartners';
import { Link } from 'react-router-dom';
import advImg1 from '../../../img/Advantages1.png'
import advImg2 from '../../../img/advantages2.png'
import advImg3 from '../../../img/advantages3.png'
import advImg4 from '../../../img/advantages4.png'
import { homeScroll } from '../../../smoothScroll';


export default function Autodialing() {
    return (
        <>
            <div className='container-fluid' style={{ backgroundColor: '#f4f4f4' }}>
            </div> 
            <div className='container-fluid mb-5 mx-0 row p-0 my-5' style={{ backgroundColor: "", marginTop: `${window.innerWidth>576?'10rem':'3rem'}`}}>
                <div className='col-md-6 col-12 what-Ad-container p-0'>
                    <div className='level1' style={{ backgroundColor: 'rgba(253,224,44,.91)', zIndex: '99' }}>
                        <div className='what-Ad'>
                            {window.innerWidth>576 &&<span className='head fw-bold text-uppercase text-white'> What Auto-dialing is? <br /></span>}
                            {window.innerWidth<576 &&<p className='head fw-bold text-uppercase text-white text-center mt-2'> What Auto-dialing is? <br /></p>}
                            <span className='content'><br />
                                Telemarketing is marketing your products or services directly to your potential clients, over the phone.
                                This highly personal approach is an extremely powerful selling method when done right. Telemarketing is cost-effective, effortlessly multiplies your reach, and is one of the best ways to build rapport with your customers.
                                You can get instant engagement, instant feedback, and instant sales.
                                <br /> <br />Autodialing systems can be set up to dial numbers sequentially, randomly, or based on specific criteria. They can also incorporate features such as prerecorded messages (known as robocalls) or interactive voice response (IVR) systems to handle call interactions without the need for human agents.
                            </span>
                        </div>
                    </div>
                </div>
                {window.innerWidth>576 && <div className='col-md-6 col-12' style={{ backgroundColor: 'rgba(15,195,250,.8)' }}>
                    <div className='what-Ad-img py-5'>
                        <img className="image" src="https://ozonetel.com/wp-content/uploads/2023/02/Automated-Telemarketing-min.jpg" alt="" />
                    </div>
                </div>}
            </div>
            <div className='application-features my-5' style={{ backgroundColor: '' }}>
                <div className='container text-center head'>
                    <span>Telemarketing Application features</span>
                </div>

                <div className="row row-cols-1 row-cols-md-2 g-md-4 g-1 p-md-5 p-1 group">
                    <div className="col">
                        <div className="card h-100 border-0 shadow">
                            <div className='row'>
                                <div className='col-4 d-flex align-items-center justify-content-center'><img src="https://ozonetel.com/wp-content/uploads/2021/09/Call-Recordings.svg" className="card-img" alt="..." /></div>
                                <div className="card-body col-8">
                                    <h4 className="card-title fw-bold">Call recording</h4>
                                    <p className="card-text">Record calls for quality, compliance, and training purposes. All call recordings are stored on the cloud and are easy to access from anywhere.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100 border-0 shadow">
                            <div className='row'>
                                <div className='col-4 d-flex align-items-center justify-content-center'><img src="https://ozonetel.com/wp-content/uploads/2021/09/Flexible-Dialing-Logic.svg" width="120px" height="120px" className="card-img" alt="..." /></div>
                                <div className="card-body col-8">
                                    <h4 className="card-title fw-bold">Flexible dialing logic</h4>
                                    <p className="card-text">Administrators can configure the dialing logic they want. Determine what best suits the data value and let the program go to work. Choose between “agent-first” or “customer-first” logic.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100 border-0 shadow">
                            <div className='row'>
                                <div className='col-4 d-flex align-items-center justify-content-center'><img src="https://ozonetel.com/wp-content/uploads/2021/09/Over-70-Reports.svg" className="card-img" alt="..." /></div>
                                <div className="card-body col-8">
                                    <h4 className="card-title fw-bold">Over 70 reports</h4>
                                    <p className="card-text">Get deep insight into operations with real-time analytic monitoring and over 70 performance reports. These actionable reports cover inbound metrics, outbound metrics, performance management, and customer experience. Managers can monitor service level adherence, see how many agents are logged in at any time, see the calls in queue, and see how many calls are abandoned.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100 border-0 shadow">
                            <div className='row'>
                                <div className='col-4 d-flex align-items-center justify-content-center'><img src="https://ozonetel.com/wp-content/uploads/2021/09/Live-Call-Monitoring-1.svg" className="card-img" alt="..." /></div>
                                <div className="card-body col-8">
                                    <h4 className="card-title fw-bold">Live call monitoring</h4>
                                    <p className="card-text">Supervisors have a suite of tools for call quality management. They can listen in on calls in progress, and join live calls with three options: silently, communicating with the agent only, or communicating with both the agent and the lead.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100 border-0 shadow">
                            <div className='row'>
                                <div className='col-4 d-flex align-items-center justify-content-center'><img src="https://ozonetel.com/wp-content/uploads/2021/09/API-Availability.svg" className="card-img" alt="..." /></div>
                                <div className="card-body col-8">
                                    <h4 className="card-title fw-bold">API availability</h4>
                                    <p className="card-text">Build customized solutions for your business with our open API library. Create the perfect automated workflow, integrate with your other tools, build custom logic, and more. Ozonetel is robust enough to build you the communication solution of your dreams.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100 border-0 shadow">
                            <div className='row'>
                                <div className='col-4 d-flex align-items-center justify-content-center'><img src="https://ozonetel.com/wp-content/uploads/2021/09/DND-Scubbing.svg" className="card-img" alt="..." /></div>
                                <div className="card-body col-8">
                                    <h4 className="card-title fw-bold">DND scrubbing</h4>
                                    <p className="card-text">Stay compliant with automated DND scrubbing for outbound calls. Your agents won’t waste any more time with these calls. And their overall morale will go up when they no longer have to deal with these calls.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100 border-0 shadow">
                            <div className='row'>
                                <div className='col-4 d-flex align-items-center justify-content-center'><img src="https://ozonetel.com/wp-content/uploads/2021/09/Pre-Recorded-Voicemail-Messages.svg" className="card-img" alt="..." /></div>
                                <div className="card-body col-8">
                                    <h4 className="card-title fw-bold">Pre-recorded voicemail messages</h4>
                                    <p className="card-text">What happens when your agent or your dialer reaches a prospect’s voicemail? It needn’t be a wasted call, nor does your agent need to waste time leaving a message. You can pre-record messages to be left as voicemails for unanswered calls.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100 border-0 shadow">
                            <div className='row'>
                                <div className='col-4 d-flex align-items-center justify-content-center'><img src="https://ozonetel.com/wp-content/uploads/2021/09/SMS-Follow-Ups.svg" className="card-img" alt="..." /></div>
                                <div className="card-body col-8">
                                    <h4 className="card-title fw-bold">SMS follow-ups</h4>
                                    <p className="card-text">Automatically follow-up your telemarketing calls with an SMS text message. Agents can send SMS messages out immediately after a call while the conversation is still fresh in the lead’s mind. Either to deepen a successful sales message or to smooth things over. They can also use text to follow up after missed calls and voice mail.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100 border-0 shadow">
                            <div className='row'>
                                <div className='col-4 d-flex align-items-center justify-content-center'><img src="https://ozonetel.com/wp-content/uploads/2021/09/Interactive-Voice-Response-IVR.svg" className="card-img" alt="..." /></div>
                                <div className="card-body col-8">
                                    <h4 className="card-title fw-bold">Interactive woice response IVR</h4>
                                    <p className="card-text">Greet incoming callers and be used to automate outbound call messages with Ozonetel's easy-to-setup IVR. Send customers automated reminders, notifications, or greetings. Your IVR can also record basic responses in the form of DTMF or voice recordings.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container-fluid get-demo text-center text-white' style={{ height: '12.4rem', background: '' }}>
                <p className='text-uppercase fw-bold fs-2'>Automated calling software to increase sales efficiency</p>
                <p>Improve your telecalling experience</p>
                <div onClick={()=>{homeScroll('form')}} className='bubble-div my-md-4 my-1'><Link to="/contact" className="btn-hover-bg btn btn-primary bubble-btn rounded-pill text-white py-md-3 py-1 px-md-5 px-3"><span className=''>Request a Demo!</span></Link></div>
            </div>

            <div className='container-fluid advantages my-5 p-0'>
                <div className='row p-0'>
                    <div className='col-6'>
                        <div className='content'>
                            <span className='head fw-bold'>Start calling with a ready to use easy calling software <br /></span>
                            <span className='paragraph' style={{fontSize:'14px'}} ><br />Experience a no-fuss ready-to-use calling software with <span className='text-primary'>cloud- <br /><br />equipped</span> technology. No more excruciating deployment processes or <br /><br /> exorbitant infrastructure upgrading costs. <br /></span>
                            <ul>
                                <li className='widget'>Auto-dial & Click-to-Dial option on phone <br /></li>
                                <li className='widget'>The leads are automatically routed to the right available agent <br /></li>
                                <li className='widget'>Track <span className='text-primary'>call analytics</span> to measure sales KPI <br /></li>
                                <li className='widget'>Use SIM as a calling medium <br /></li>
                            </ul>
                        </div>

                    </div>
                    <div className='col-6'><img src={advImg1} className='img1'  alt="" /></div>
                    <div className='col-6'><img src={advImg3} className='img2'  alt="" /></div>
                    <div className='col-6'>
                        <div className='content'>
                            <span className='fs-4 fw-bold'>Start calling with a ready to use easy calling software <br /></span>
                            <span style={{ fontSize: '14px' }}><br />Experience a no-fuss ready-to-use calling software with <span className='text-primary'>cloud- <br /><br />equipped</span> technology. No more excruciating deployment processes or <br /><br /> exorbitant infrastructure upgrading costs. <br /></span>
                            <ul>
                                <li className='widget'>Auto-dial & Click-to-Dial option on phone <br /></li>
                                <li className='widget'>The leads are automatically routed to the right available agent <br /></li>
                                <li className='widget'>Track <span className='text-primary'>call analytics</span> to measure sales KPI <br /></li>
                                <li className='widget'>Use SIM as a calling medium <br /></li>
                            </ul>
                        </div>
                    </div>

                    <div className='container-fluid get-demo rounded text-center text-white' style={{ height: '11.4rem', background: '' }}>
                        <p className=' text-center text-uppercase fw-bold fs-2'>Automated telemarketing software</p>
                        <p>Efficiently Maximize Your Outreach.</p>
                        <div onClick={()=>{homeScroll('form')}}className='bubble-div my-4'><Link to="/contact" className="btn-hover-bg btn btn-primary bubble-btn rounded-pill text-white py-md-3 py-1 px-md-5 px-2"><span className=''>Request a Demo!</span></Link></div>
                    </div>

                    <div className='col-6'>
                        <div className='content'>
                            <span className='fs-4 fw-bold'>Start calling with a ready to use easy calling software <br /></span>
                            <span style={{ fontSize: '14px' }}><br />Experience a no-fuss ready-to-use calling software with <span className='text-primary'>cloud- <br /><br />equipped</span> technology. No more excruciating deployment processes or <br /><br /> exorbitant infrastructure upgrading costs. <br /></span>
                            <ul>
                                <li className='widget'>Auto-dial & Click-to-Dial option on phone <br /></li>
                                <li className='widget'>The leads are automatically routed to the right available agent <br /></li>
                                <li className='widget'>Track <span className='text-primary'>call analytics</span> to measure sales KPI <br /></li>
                                <li className='widget'>Use SIM as a calling medium <br /></li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-6'><img src={advImg2} className='img3' alt="" /></div>
                    <div className='col-6'><img src={advImg4} className='img4'  alt="" /></div>
                    <div className='col-6'>
                        <div className='content'>
                            <span className='fs-4 fw-bold'>Start calling with a ready to use easy calling software <br /></span>
                            <span style={{ fontSize: '14px' }}><br />Experience a no-fuss ready-to-use calling software with <span className='text-primary'>cloud- <br /><br />equipped</span> technology. No more excruciating deployment processes or <br /><br /> exorbitant infrastructure upgrading costs. <br /></span>
                            <ul>
                                <li className='widget'>Auto-dial & Click-to-Dial option on phone <br /></li>
                                <li className='widget'>The leads are automatically routed to the right available agent <br /></li>
                                <li className='widget'>Track <span className='text-primary'>call analytics</span> to measure sales KPI <br /></li>
                                <li className='widget'>Use SIM as a calling medium <br /></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container-fluid'>
                <div className='text-center fs-1 fw-bolder my-5'>Why The AutoDial?</div>
                <div className='row p-5'>
                    <div className='col-md-4 col-12 card shadow rounded'>
                        <div className='h-100'>
                            <span className='fs-5 fw-bold'>Robust & Secure <br /><br /></span>
                            <ul className='why-chooseUs'>
                                <li>
                                <i style={{color:'#3FE33F'}}aria-hidden="true" class="fas fa-check-circle"></i>
                                <span> Handle billions of calls anually.</span>
                                </li>
                                <li className='widget'>
                                <i style={{color:'#3FE33F'}}aria-hidden="true" class="fas fa-check-circle"></i>
                                <span> 99.9% uptime</span>
                                </li>
                                <li className='widget'>
                                <i style={{color:'#3FE33F'}}aria-hidden="true" class="fas fa-check-circle"></i>
                                <span> ISO 27001 & 20000 certified</span>
                                </li>
                                <li className='widget'>
                                <i style={{color:'#3FE33F'}}aria-hidden="true" class="fas fa-check-circle"></i>
                                <span> HIPAA compliant</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-md-4 col-12  card shadow rounded'>
                        <div className=' h-100'>
                        
                            <span className='fs-5 fw-bold'>Robust & Secure <br /><br /></span>
                            <ul className='why-chooseUs'>
                                <li>
                                <i style={{color:'#3FE33F'}}aria-hidden="true" class="fas fa-check-circle"></i>
                                <span> Monthly pricing. Scale up or down in minutes</span>
                                </li>
                                <li className='widget'>
                                <i style={{color:'#3FE33F'}}aria-hidden="true" class="fas fa-check-circle"></i>
                                <span> Open API library for customizatation if needed.</span>
                                </li>
                                <li className='widget'>
                                <i style={{color:'#3FE33F'}}aria-hidden="true" class="fas fa-check-circle"></i>
                                <span> IOption to work with your existing SIP trunk.</span>
                                </li>
                            </ul>
                        
                        </div>                    
                    </div>
                    <div className='col-md-4 col-12 card shadow rounded'>
                    <div className='h-100 '>
                            <span className='fs-5 fw-bold'>Steller support <br /><br /></span>
                            <ul className='why-chooseUs'>
                                <li>
                                <i style={{color:'#3FE33F'}}aria-hidden="true" class="fas fa-check-circle"></i>
                                <span> 24 x 7 live customer support teams available</span>
                                </li>
                                <li className='widget'>
                                <i style={{color:'#3FE33F'}}aria-hidden="true" class="fas fa-check-circle"></i>
                                <span> Free onboarding and training</span>
                                </li>
                                <li className='widget'>
                                <i style={{color:'#3FE33F'}}aria-hidden="true" class="fas fa-check-circle"></i>
                                <span> One point contact from sales to onboarding</span>
                                </li>
                            </ul>
                        </div>                   
                    </div>
                </div>
            </div>
        </>
    )
}
